import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { DropDown } from '../../../../client/dashboard/settings/settings.model';
import {
  Reviewer,
  SearchMarket,
} from '../../../../../store/reviewer/reviewer.interface';
import { SearchMarketService } from '../../../../../shared/services/search-market.service';
import { ReviewerService } from '../../../../../shared/services/reviewer.service';
import { JobTypeService } from '../../../../../shared/services/job-type.service';
import { lastValueFrom } from 'rxjs';
import { ToastService } from '../../../../../shared/services/toast.service';
import { UserService } from '../../../../../shared/services/user.service';
import { ResetPasswordData } from '../../../../../store/user/user.interface';
import { AuthService } from '../../../../../shared/services/auth.service';
import { ProfileInformationClass } from '../../../../../shared/components/profile-information/profile-information.component';
import { TooltipModule } from 'primeng/tooltip';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationDialogService } from '../../../../../shared/services/confirmation-dialog.service';
import { MergeAccountComponent } from '../merge-account-dialog/merge-account-dialog.component';
import { EmittersService } from '../../../../../shared/services/emitters.service';
import { constants } from '../../../../../shared/constants/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-information',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FloatLabelModule,
    CalendarModule,
    InputTextModule,
    DropdownModule,
    HeadingComponent,
    MultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordModule,
    TooltipModule,
  ],
  templateUrl: './contact-information.component.html',
  styleUrl: './contact-information.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ContactInformationComponent extends ProfileInformationClass{
  @Output() submitData: EventEmitter<Reviewer> = new EventEmitter();
  @Input() from?: string = '';
  @Input() firmId?: string = '';
  @Input() mergeInvite: boolean = false;
  searchMarketDropdown: DropDown[] = [];
  jobType: DropDown[] = [];
  verificationCode!: FormControl;
  searchMarketValues: SearchMarket[] = [];
  jobCategoryValues: string[] = [];
  showPasswordField: boolean = false;
  ref: DynamicDialogRef | undefined;
  skillsCriteriaListing: any;
  existingCriterias: any;
  constructor(
    private searchMarketService: SearchMarketService,
    private confirmationDialogService: ConfirmationDialogService,
    private dialogService: DialogService,
    private jobTypeService: JobTypeService,
    private authService: AuthService,
    fb: FormBuilder,
    reviewerService: ReviewerService,
    toastService: ToastService,
    userService: UserService,
    private eventEmitterService: EmittersService,
    private router: Router,
  ) { 
    super(fb, reviewerService, toastService, userService)
  }

  async ngOnInit(): Promise<void> {
    this.initializeForm();
    await this.getReviewerInfo();
    this.getTenant();
    await this.setSearchMarketDropdown();
    await this.setJobType();
    this.isPhoneVerified = this.reviewerInfo?.profile?.contact?.isPhoneVerified;
    this.populateData();
  }

  override initializeForm(): void {
    super.initializeForm();

    const profileGroup = this.profileForm.get('profile') as FormGroup;
    profileGroup.addControl('jobCategories', this.fb.control('', Validators.required));
    profileGroup.addControl('searchMarkets', this.fb.control('', Validators.required));
    this.profileForm.addControl('password', this.fb.control('', Validators.required));
  }

  override async getReviewerInfo(): Promise<void> {
    await super.getReviewerInfo();
    this.searchMarketValues = this.reviewerInfo?.profile?.searchMarkets.map(
      (item: any) => item._id
    );
    this.jobCategoryValues = this.reviewerInfo?.profile?.jobCategories.map(
      (item: any) => item
    );
    this.showPasswordField = this.reviewerInfo && this.reviewerInfo.externalInvite && this.reviewerInfo.lastPasswordChangedAt === null ? true : false;
    if(this.showPasswordField){
      let verifiedTenantIndex = this.reviewerInfo.tenants.findIndex((e: any) => e.reviewerStatus === constants.userVerificationStatus.verified || e.reviewerStatus === constants.userVerificationStatus.pendingAdminVerification);
      this.showPasswordField = verifiedTenantIndex < 0;
    }
    if (!this.showPasswordField) {
      this.disbalePasswordField();
    }
  }

  async setSearchMarketDropdown(): Promise<void> {
    const firm = this.firmId ? this.firmId : this.userFirmId;
    const res = await lastValueFrom(
      this.searchMarketService.getFirmSearchMarkets(firm.toString())
    );
    res.forEach((item: any) => {
      if(item.isActive){
        this.searchMarketDropdown.push({
          label: item?.name,
          value: item?._id,
        });  
      }
    });
  }

  async setJobType(): Promise<void> {
    const firm = this.firmId ? this.firmId : this.userFirmId;
    const res = await lastValueFrom(
      this.jobTypeService.getFirmJobType(firm.toString())
    );
    res.forEach((item: any) => {
      if(!item.isArchived){
        this.jobType.push({
          label: item?.name,
          value: item?._id,
        });  
      }
    });
  }

  override async sendVerificationCode(): Promise<void> {
    this.authService
      .sendAdminVerificationPhoneSms(
        this.reviewerInfo?._id,
        this.profileForm.get('profile.contact.home')?.value.replace(/-/g, '')
      )
      .subscribe({
        next: async (res) => {
          if (res.message === 'Phone number successfully verified') {
            this.isPhoneVerified = true;
            this.disablePhoneField();
          }
        },
      });
  }

  disbalePasswordField(): void {
    (this.profileForm.get('password') as FormControl).disable();
  }
  override populateData(): void {
    super.populateData();
    let arrayOfMatchedvalues: any[] = [];
    this.reviewerInfo?.profile?.searchMarkets.forEach((market: any) => {
      const match = this.searchMarketDropdown.find(
        (markets) => markets.value === market._id
      );
      if (match) {
        arrayOfMatchedvalues.push(match);
      }
    });
    this.setFormValues('profile.searchMarkets', arrayOfMatchedvalues);
    arrayOfMatchedvalues = []
    this.reviewerInfo?.profile?.jobCategories.forEach((category: any) => {
      const match = this.jobType.find((job) => job.value === category);
      if (match) {
        arrayOfMatchedvalues.push(match);
      }
    });
    this.setFormValues('profile.jobCategories', arrayOfMatchedvalues);
  }

  extractDropdownValues(values: any): any {
    const extractedArray: string[] = values.map(
      (category: any) => category.value
    );
    return extractedArray;
  }

  onSearchMarketDropdownChange($event: any): void {
    const selectedValues = $event.value;
    if($event.itemValue){
      const removedItems = !selectedValues.includes($event?.itemValue) ? $event.itemValue : {};
      this.searchMarketValues = this.searchMarketValues.filter(value => value !== removedItems.value || '');
    }
    this.searchMarketValues = [
      ...new Set([...this.searchMarketValues, ...selectedValues.map((item: any) => item.value)]),
    ];
  }

  onJobCategoryDropdownChange($event: any): void {
    const selectedValues = $event.value;
    if($event.itemValue){
      const removedItems = !selectedValues.includes($event?.itemValue) ? $event.itemValue : {};
      this.jobCategoryValues = this.jobCategoryValues.filter(value => value !== removedItems.value || '');
    }
    this.jobCategoryValues = [
      ...new Set([...this.jobCategoryValues, ...selectedValues.map((item: any) => item.value)]),
    ];
  }

  override onSubmit(): void {
    if (this.isPhoneVerified) {
      if (this.profileForm.valid) {
        if (this.showPasswordField) {
          const currentPassword = this.profileForm.value.password;
          const passwordData: ResetPasswordData = {
            externalInvite: this.reviewerInfo.externalInvite,
            recoverHash: this.reviewerInfo.recoverHash,
            newPassword: currentPassword,
            confirmPassword: currentPassword
          };
          this.userService.resetPassword(passwordData).subscribe((resp: any) => {
            this.reviewerInfo.lastPasswordChangedAt = resp?.lastPasswordChangedAt;
            this.reviewerInfo.isPasswordUpdated = resp?.isPasswordUpdated;
            this.updateReviewer();
          })
        }
       else {
        this.updateReviewer();
      }
    }
    } else {
      this.toastService.showError('Phone number no verified');
    }
  }
  
  override updateReviewer() {
    const payload: Reviewer = {
      ...this.reviewerInfo,
      profile: {
        ...this.reviewerInfo.profile,
        contact: {
          ...this.profileForm.get('profile.contact')?.value,
          state: this.profileForm.get('profile.contact.state')?.value?.name,
          home: this.profileForm.get('profile.contact.home')?.value?.replace(/-/g, ''),
          isPhoneVerified: this.isPhoneVerified,
        },
        jobCategories: this.jobCategoryValues,
        searchMarkets: this.searchMarketValues,
      },
      firstName: this.profileForm.get('firstName')?.value,
      lastName: this.profileForm.get('lastName')?.value,
      email: this.profileForm.get('email')?.value,
    };
    
    this.reviewerService.updateReviewer(payload).subscribe({
      next: async (res) => {
        this.reviewerInfo =res
        this.toastService.showSuccess('Updated Successfully');
        this.reviewerService.fetchReviewerAndUpdate();
        await this.save();
        await super.getReviewerInfo();
        this.submitData.emit(res);
        this.eventEmitterService.skillAndExperienceEmitter.emit(res);
        this.eventEmitterService.credentialEmitter.emit(res);

      },
    });
  }

  mergeAccount(){
    this.ref = this.dialogService.open(MergeAccountComponent, {
      header: 'Merge Staftr Account',
      width: '30vw',
      contentStyle: { overflow: 'hidden' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
    });
    this.ref.onClose.subscribe((data: any) => {
      if (data) {
        const firmId = this.firmId ? this.firmId : this.userFirmId;
        this.reviewerService.mergeAccount(firmId, data.email).subscribe((reviewer) => {
          console.log(reviewer);
          this.toastService.showSuccess(`Thank you for merging this invite into your existing Staftr account. Please check your ${data.email} inbox for a merge verification link`);
        });
      }
    });
  }

  formatContact(event: any): void {
    let input = event.target.value.replace(/\D/g, '');
    let formattedInput = '';
    if (input.length > 0) {
      formattedInput = input.substring(0, 3);
  }
  if (input.length > 3) {
      formattedInput += '-' + input.substring(3, 6);
  }
  if (input.length > 6) {
      formattedInput += '-' + input.substring(6, 10);
  }
    event.target.value = formattedInput;  
  }

  async save(){
    this.reviewerService.getFilteredCriteriaBasedUserSkills(this.reviewerInfo._id).subscribe((skillsAndExperience) => {
      this.skillsCriteriaListing = skillsAndExperience
      let criterias:any = {};
      this.skillsCriteriaListing.map((criteriaItem:any)=>{
        let tenantCriteriaFound = false;

        this.reviewerInfo.tenants.forEach((tenant: any) => {
          tenant.criterias.forEach((tenantCriteria: any) => {
            if (tenantCriteria.criteriaId === criteriaItem._id) {
              criterias[criteriaItem.name] = {
                selectedOptions: tenantCriteria.criteriaSelectedOption,
                jobCategory: tenantCriteria.jobCategory,
                criteriaId: tenantCriteria.criteriaId,
              };
              tenantCriteriaFound = true;
            }
          });
          if (!tenantCriteriaFound) {
            criterias[criteriaItem.name] = {
              selectedOptions: [],
              jobCategory: criteriaItem.jobCategory.map((category: any) => category._id),
              criteriaId: criteriaItem._id
            };
          }
        });
      });
      const payload = {
        criterias: {
          ...criterias,
        },
        reviewerId: this.reviewerInfo._id
      }
      this.reviewerService.saveCriteriaReviewer(payload).subscribe((d:any)=>{
      })
    })
  }

  onBack(): void {
    this.router.navigate(['/reviewer/dashboard'])
  }
}
